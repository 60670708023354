.main-content {
  ul, ol {
    padding-left: 0;
    list-style: none;
    &, li {
      font-family: Museo, sans-serif;
      color: #6e6e6e;
      font-size: 18px;
      line-height: 32px; } }

  blockquote {
    //width: 130%
    margin-bottom: 2em;
    padding-left: 1em;
    border-left: 4px solid #aac184;
    //-webkit-transform: translate(-11.5%, 0px)
    //-ms-transform: translate(-11.5%, 0px)
    //transform: translate(-11.5%, 0px)
    font-family: Museo, sans-serif;
    color: #6e6e6e;
    font-size: 24px;
    line-height: 1.47em;
    font-weight: 300; } }

