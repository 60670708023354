.left-control,
.right-control {
  position: absolute;
}

@media (max-width: 767px) {
  .left-control,
  .right-control {
    position: relative;
  }
}

@media (max-width: 479px) {
  .home-content {
    padding:1em;
  }
  .hero.service-hero {
    height:300px;
  }
}