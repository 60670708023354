body {
  height: 100%;
  font-family: Cabin, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
  margin-bottom: 0.5em;
  font-family: Museo, sans-serif;
  color: #4a4437;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Museo Bold', sans-serif;
  color: #4a4437;
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
}

p {
  margin-bottom: 1.47em;
  color: #6e6e6e;
  font-size: 18px;
  line-height: 22px;
}

a {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #6e6e6e;
  text-decoration: none;
}

a:hover {
  color: #8b9e6b;
}

img {
  display: inline-block;
  max-width: 100%;
}

.hero {
  position: relative;
  height: 600px;
  padding: 2em 4em;
  background-image: url('../images/hero-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  font-family: 'Museo Bold', sans-serif;
}

.hero.service-hero {
  height: 500px;
}

.hero.service-hero.ind-exp {
  padding-right: 2em;
  padding-left: 2em;
  background-image: url('../images/indexp-hero.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.hero.service-hero.challenges-hero {
  position: relative;
  background-image: url('../images/mountains.jpg');
  background-size: cover;
}

.hero.service-hero.strategy {
  position: relative;
  background-image: url('../images/strategy.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.hero.service-hero.howwework-hero {
  background-image: url('../images/howwework.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.top-header {
  height: 3em;
}

.logo {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  margin-top: 1em;
  margin-left: 1em;
  padding: 1em 2em;
  float: left;
  border-radius: 3px;
  background-color: #fff;
}

.logo.logov2 {
  padding: 0em;
}

.header-tag {
  display: block;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5em;
  float: right;
  font-family: Cabin, sans-serif;
  font-size: 1em;
  text-align: center;
}

.burger-menu {
  margin-top: 1em;
  margin-right: 1em;
  float: right;
}

.hero-main {
  position: relative;
}

.slider {
  display: block;
  width: 100%;
  height: 600px;
  margin-top: 4em;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
}

.service-navbar {
  z-index: 2;
  background-color: transparent;
  color: #fff;
}

.service-navbar.navbarv2 {
  color: #6e6e6e;
}

.service-nav {
  font-family: Cabin, sans-serif;
  color: #fff;
  font-weight: 400;
}

.service-nav:hover {
  color: hsla(0, 0%, 100%, .49);
}

.service-nav.w--current {
  border-radius: 3px;
  background-color: hsla(82, 21%, 52%, .56);
  color: #fff;
}

.service-nav.service-nav-v2 {
  padding-right: 1em;
  padding-left: 1em;
  color: #fff;
}

.service-nav-menu {
  clear: right;
}

.hero-h1 {
  margin-top: 4em;
  font-family: 'Museo Bold', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 57px;
  font-weight: 700;
  text-align: center;
}

.hero-h1.service-page {
  position: relative;
  width: 100%;
  margin-top: 2em;
  text-align: center;
}

.home-content {
  padding-top: 4em;
  padding-bottom: 0em;
}

.slider-img {
  width: 50%;
  height: 33em;
  background-image: url('../images/temp1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-copy {
  position: relative;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 2em 3em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: none;
  border-width: 3px;
  border-color: hsla(0, 0%, 96%, .37);
  border-radius: 5px;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .44);
}

.slide-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  float: left;
}

.slide-img.slide-img1 {
  background-image: url('../images/strategy.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img2 {
  background-image: url('../images/plan.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img3 {
  background-image: url('../images/design.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img4 {
  background-image: url('../images/procure.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img5 {
  background-image: url('../images/impliment2.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img6 {
  background-image: url('../images/operate.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.slide-img.slide-img7 {
  background-image: url('../images/Optimise.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.right-control {
  position: relative;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  border: 6px solid #fff;
  border-radius: 3px;
  background-color: #f4f4f4;
  background-image: url('../images/arrow-right.svg');
  background-position: 50% 50%;
  background-size: 10px;
  background-repeat: no-repeat;
}

.left-control {
  position: static;
  display: block;
  width: 2em;
  height: 2em;
  margin-left: 0.5em;
  float: left;
  border: 6px solid #fff;
  border-radius: 3px;
  background-color: #f4f4f4;
  background-image: url('../images/arrow-left.svg');
  background-position: 50% 50%;
  background-size: 10px;
  background-repeat: no-repeat;
}

.hero-slider-button {
  padding: 1em 2em;
  border: 4px solid #8b9e6b;
  border-radius: 1005px;
  background-color: #8b9e6b;
  -webkit-transition: border-color 500ms ease, background-color 500ms ease;
  transition: border-color 500ms ease, background-color 500ms ease;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hero-slider-button:hover {
  border: 4px solid #f4f4f4;
  background-color: transparent;
  color: #f4f4f4;
}

.hero-slider-button.nav-button {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 165px;
  margin-bottom: 2em;
  margin-left: 2em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-color: #f4f4f4;
  background-color: transparent;
}

.hero-slider-button.nav-button:hover {
  border-color: #fff;
  color: #fff;
}

.intro-statement {
  display: block;
  width: 80%;
  margin: 4em auto;
  text-align: center;
}

.nav-block-container {
  height: 70vh;
  margin-bottom: 8em;
}

.nav-block-item {
  position: relative;
  height: 300px;
  margin-bottom: 1em;
}

.nav-block-item.challenges {
  background-image: url('../images/challenges2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.nav-block-item.industry {
  background-image: url('../images/industryexperience.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.nav-block-item.howwework {
  background-image: url('../images/howwework.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.nav-block-item.btn {
  display: inline;
  color: #fff;
}

.gradient-mask {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  padding: 3em 2em 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-image: -webkit-linear-gradient(270deg, hsla(82, 21%, 52%, .6), transparent);
  background-image: linear-gradient(180deg, hsla(82, 21%, 52%, .6), transparent);
  -webkit-transition: background-color 200ms linear, opacity 200ms ease;
  transition: background-color 200ms linear, opacity 200ms ease;
}

.gradient-mask:hover {
  background-color: #8b9e6b;
  background-image: url('../images/brand-bits_1.svg'), -webkit-linear-gradient(270deg, hsla(82, 21%, 52%, .6), transparent);
  background-image: url('../images/brand-bits_1.svg'), linear-gradient(180deg, hsla(82, 21%, 52%, .6), transparent);
  background-position: 100% 100%, 0px 0px;
  background-size: 200px, auto;
  background-repeat: no-repeat, repeat;
}

.nav-block-h1 {
  font-family: Museo, sans-serif;
  color: #fff;
  line-height: 28px;
  font-weight: 300;
  text-align: left;
}

.nav-block-a {
  position: absolute;
  width: 100%;
  height: 100%;
}

.col-nopad {
  height: 100%;
}

.row-cta {
  margin-bottom: 4em;
  padding-right: 2em;
  padding-left: 2em;
}

.h2-mob-small {
  font-family: Museo, sans-serif;
  font-weight: 300;
  text-align: center;
}

.h2-mob-small.contact {
  margin-bottom: 1em;
  text-align: left;
}

.row {
  height: 100%;
}

.hero-p {
  margin-bottom: 2em;
  color: #f4f4f4;
  font-size: 26px;
  line-height: 35px;
  font-weight: 400;
}

.hero-h2 {
  margin-bottom: 0.5em;
  font-family: 'Museo Bold', sans-serif;
  color: #f4f4f4;
}

.hero-mask {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(270deg, rgba(128, 146, 98, .6), rgba(74, 68, 55, .6));
  background-image: linear-gradient(180deg, rgba(128, 146, 98, .6), rgba(74, 68, 55, .6));
  opacity: 1;
}

.content {
  padding-top: 2em;
}

.contact-deets {
  width: 100%;
}

.contact-deet {
  margin-top: 1em;
  font-family: Museo, sans-serif;
  color: #4a4437;
  font-size: 32px;
  font-weight: 300;
  text-decoration: none;
}

.linkedin {
  margin-right: 1em;
}

.social-links {
  margin-top: 2em;
}

.footer-img {
  width: 100%;
  height: 235px;
  background-image: url('../images/oo.svg');
  background-position: 50% 100%;
  background-repeat: repeat-x;
  -webkit-transform: translate(0px, -235px);
  -ms-transform: translate(0px, -235px);
  transform: translate(0px, -235px);
}

.site-wrapper {
  padding-right: 0em;
  padding-left: 0em;
}

.client-logo {
  margin-bottom: 1em;
}

.intro-paragraph {
  font-family: Museo, sans-serif;
  color: #6e6e6e;
  font-size: 24px;
  line-height: 31px;
}

.client-listing {
  width: 25%;
  padding-right: 0.5em;
  padding-left: 0.5em;
  float: left;
  text-align: center;
}

.client-listing-container {
  margin-top: 3em;
}

.listing-client-logo {
  margin-top: 1em;
  margin-bottom: 1em;
}

.button-alt {
  border-radius: 3px;
  background-color: #f4f4f4;
  color: #8b9e6b;
  font-size: 12px;
  letter-spacing: 1px;
}

.button-alt:hover {
  background-color: #8b9e6b;
  color: #fff;
}

.center {
  color: #6e6e6e;
  text-align: center;
}

.logo-block {
  width: 50%;
  float: right;
}

.inline-image {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.inline-image._2up {
  width: 98%;
}

.inline-img {
  min-width: 100%;
  height: auto;
}

.inline-image-1up-wrap {
  width: 100%;
  margin-top: 3em;
  margin-bottom: 2em;
}

.img-caption {
  display: inline-block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  float: right;
  color: #574008;
  font-style: italic;
  font-weight: 400;
}

.block-quote {
  //width: 130%;
  margin-bottom: 2em;
  padding-left: 1em;
  border-left: 4px solid #aac184;
  //-webkit-transform: translate(-11.5%, 0px);
  //-ms-transform: translate(-11.5%, 0px);
  //transform: translate(-11.5%, 0px);
  font-family: Museo, sans-serif;
  color: #6e6e6e;
  font-size: 24px;
  line-height: 1.47em;
  font-weight: 300;
}

.testimonial-block {
  margin-top: 4em;
  margin-bottom: 2em;
  padding: 2em;
  border-radius: 3px;
  background-color: #f4f4f4;
  font-size: 18px;
  font-style: italic;
}

.testimonial {
  color: #6e6e6e;
  font-size: 18px;
  line-height: 21.76px;
}

.testimonial-p {
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
}

.avatar {
  display: inline-block;
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin-right: 0.75em;
  float: left;
  border-radius: 100%;
}

.testi-name {
  display: inline-block;
  float: left;
  color: #6e6e6e;
  font-size: 16px;
}

.testi-company {
  display: inline-block;
  float: left;
  clear: left;
  color: #8b9e6b;
  font-size: 16px;
}

.testimonial-persondetails {
  display: inline-block;
  padding-top: 1em;
}

.previous-page {
  display: inline-block;
  border-radius: 3px;
  background-color: #f4f4f4;
}

.prev-page {
  padding: 1em 2em;
  border-radius: 3px;
  background-color: #f4f4f4;
  background-image: url('../images/left-control-cs.svg');
  background-position: 32px 50%;
  background-size: 8px;
  background-repeat: no-repeat;
  font-family: Museo, sans-serif;
  font-size: 20px;
}

.next-page {
  padding: 2em 4em;
  float: right;
  border-radius: 3px;
  background-color: #f4f4f4;
  background-image: url('../images/right-control-cs.svg');
  background-position: 89% 50%;
  background-size: 8px;
  background-repeat: no-repeat;
  font-family: Museo, sans-serif;
  font-size: 20px;
}

.new-section {
  margin-top: 2em;
  margin-bottom: 2em;
}

.pagenav {
  display: inline-block;
  width: 300px;
  padding: 1em 2em;
  float: left;
  border-radius: 3px;
  background-color: #f4f4f4;
  text-align: left;
}

.pagenav.right {
  float: right;
  background-image: url('../images/right-control-cs.svg');
  background-position: 98% 50%;
  background-size: 8px;
  background-repeat: no-repeat;
  text-align: right;
}

.pagenav.left {
  background-image: url('../images/left-control-cs.svg');
  background-position: 8px 50%;
  background-size: 8px;
  background-repeat: no-repeat;
}

.hero-gradient-mask {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(270deg, hsla(82, 21%, 52%, .6), hsla(0, 0%, 100%, 0));
  background-image: linear-gradient(180deg, hsla(82, 21%, 52%, .6), hsla(0, 0%, 100%, 0));
}

.em-list {
  font-family: Museo, sans-serif;
  color: #6e6e6e;
  font-size: 18px;
  line-height: 32px;
}

.img-page-nav {
  margin-top: 4em;
  margin-bottom: 4em;
}

.img-nav {
  width: 100%;
  height: 240px;
}

.img-nav.left {
  background-image: url('../images/indexp-hero.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav.img-nav-indexp {
  position: relative;
  background-image: url('../images/indexperience-thumb.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav.img-nav-howwework {
  position: relative;
  background-image: url('../images/shudderstock.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav.img-nav-plan {
  position: relative;
  background-image: url('../images/banner-plan.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav.img-nav-optimise {
  position: relative;
  background-image: url('../images/banner-optimise.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav.img-nav-challenges {
  position: relative;
  background-image: url('../images/mountains.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.img-nav.img-nav-challenges.w--current {
  position: relative;
  display: inline-block;
  background-image: url('../images/mountains.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-nav-a {
  position: absolute;
  left: 0px;
  top: 0px;
  padding-top: 32px;
  padding-left: 16px;
  font-family: Museo, sans-serif;
  color: #fff;
  font-size: 28px;
}

.sub-nav {
  display: block;
  background-color: transparent;
}

.sub-nav-link {
  display: block;
  width: 80%;
  margin-bottom: 1px;
  border-radius: 3px;
  background-color: #f4f4f4;
  -webkit-transition: color 300ms ease, background-color 300ms ease;
  transition: color 300ms ease, background-color 300ms ease;
  color: #574008;
}

.sub-nav-link:hover {
  background-color: #8b9e6b;
  background-image: url('../images/brand-bits_1.svg');
  background-position: 100% 50%;
  background-size: 63px;
  background-repeat: no-repeat;
  color: #fff;
}

.sub-nav-link.w--current {
  background-color: #8b9e6b;
  background-image: url('../images/brand-bits_1.svg');
  background-position: 100% 50%;
  background-size: 63px;
  background-repeat: no-repeat;
  color: #fff;
}

.sub-nav-menu {
  width: 100%;
}

.logo-link {
  position: relative;
  z-index: 3;
}

.logo-link.w--current {
  position: relative;
  z-index: 2;
}

.logo-img {
  -webkit-transition: opacity 500ms ease, -webkit-transform 200ms ease;
  transition: opacity 500ms ease, -webkit-transform 200ms ease;
  transition: opacity 500ms ease, transform 200ms ease;
  transition: opacity 500ms ease, transform 200ms ease, -webkit-transform 200ms ease;
}

.logo-img:hover {
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.footer {
  position: relative;
  display: block;
  height: 400px;
  background-image: url('../images/oo.svg');
  background-position: 50% 100%;
  background-repeat: repeat-x;
}

.legal-mumbo-jumbo {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: inline-block;
  margin-bottom: 0.75em;
  color: #f4f4f4;
  text-align: center;
}

.img-copy {
  position: absolute;
  left: 4em;
  top: 0em;
  background-image: url('../images/brand-bits.svg');
  background-position: 50% 87%;
  background-size: 63px;
  background-repeat: no-repeat;
}

.headerv2 {
  padding: 1em 2em 2em;
}

html.w-mod-js *[data-ix="fade-in-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, 1em);
  -ms-transform: translate(0px, 1em);
  transform: translate(0px, 1em);
}

html.w-mod-js *[data-ix="fade-in-on-delay-1"] {
  opacity: 0;
  -webkit-transform: translate(0px, 1em);
  -ms-transform: translate(0px, 1em);
  transform: translate(0px, 1em);
}

html.w-mod-js *[data-ix="fade-in-on-load-up"] {
  opacity: 0;
  -webkit-transform: translate(0px, 1em);
  -ms-transform: translate(0px, 1em);
  transform: translate(0px, 1em);
}

html.w-mod-js *[data-ix="scroll-up-on-load-1"] {
  opacity: 0;
  -webkit-transform: translate(0px, 2em);
  -ms-transform: translate(0px, 2em);
  transform: translate(0px, 2em);
}

@media (max-width: 991px) {
  .hero {
    padding-right: 0em;
    padding-left: 0em;
  }
  .logo {
    width: 120px;
  }
  .slider {
    width: 100%;
  }
  .service-nav {
    display: block;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2em;
    float: right;
    clear: right;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    background-color: #fff;
    color: #4a4437;
    text-align: right;
  }
  .service-nav:hover {
    color: #4a4437;
  }
  .service-nav:active {
    background-color: #fff;
    color: #8b9e6b;
  }
  .service-nav.w--current {
    background-color: #8b9e6b;
  }
  .service-nav.service-nav-v2 {
    display: block;
    width: 320px;
    padding-right: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, .06);
    color: #4a4437;
    text-align: center;
  }
  .service-nav-menu {
    background-color: transparent;
    color: #4a4437;
  }
  .hero-h1 {
    display: block;
    width: 80%;
    margin-top: 6em;
    margin-right: auto;
    margin-left: auto;
    font-size: 38px;
    text-align: center;
  }
  .home-content {
    padding-top: 2em;
  }
  .home-content.home {
    padding-top: 0.5em;
  }
  .slide-copy {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .right-control {
    margin-right: 0.25em;
  }
  .left-control {
    margin-left: 0.25em;
  }
  .hero-slider-button {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .hero-slider-button.nav-button {
    margin-left: 1em;
  }
  .intro-statement {
    width: 100%;
  }
  .nav-block-container {
    height: 50vh;
  }
  .gradient-mask {
    padding: 2em 1em;
  }
  .nav-block-h1 {
    font-size: 24px;
    line-height: 1.3em;
  }
  .col-nopad {
    padding-right: 0px;
    padding-left: 0px;
  }
  .col-100 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .row-cta {
    height: auto;
  }
  .menu-btn.w--open {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fff;
    color: #4a4437;
  }
  .footer-img {
    background-size: 100%;
  }
  .sm {
    font-size: 12px;
  }
  .service-nav-button {
    font-family: Museo, sans-serif;
    font-size: 18px;
  }
  .intro-paragraph {
    margin-top: 1em;
  }
  .logo-block {
    width: 100%;
  }
  .inline-image-1up-wrap {
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .block-quote {
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .prev-page {
    padding-right: 2em;
    padding-left: 2em;
    background-position: 12px 50%;
    font-size: 16px;
  }
  .next-page {
    padding-right: 2em;
    padding-left: 2em;
    background-position: 95% 50%;
    font-size: 16px;
  }
  .hide-tab {
    display: none;
  }
  .page-nav {
    margin-bottom: 10em;
  }
  .sub-nav-link {
    width: 100%;
  }
  .sub-nav-link.w--current {
    left: 0px;
    top: 0px;
    bottom: 0px;
    display: none;
  }
  .sub-nav-menu {
    background-color: #f4f4f4;
  }
  .menu-button {
    float: left;
    background-color: #f4f4f4;
  }
  .footer {
    width: 100%;
    background-position: 100% 100%;
    background-size: 100%;
    background-attachment: scroll;
  }
  .legal-mumbo-jumbo {
    position: static;
    display: block;
    width: 50%;
    margin-top: 1em;
    color: hsla(0, 0%, 43%, .39);
    text-align: left;
  }
}

@media (max-width: 767px) {
  .hero {
    padding-right: 2em;
    padding-left: 2em;
  }
  .logo {
    width: 100px;
  }
  .header-tag {
    position: relative;
    z-index: 2;
  }
  .hero-h1 {
    margin-top: 2em;
    font-family: Museo, sans-serif;
  }
  .hero-h1.service-page {
    margin-top: 3em;
  }
  .right-control {
    position: relative;
    width: 1.5em;
    height: 1.5em;
  }
  .left-control {
    width: 1.5em;
    height: 1.5em;
  }
  .col-100 {
    margin-bottom: 1em;
  }
  .row-cta {
    margin-bottom: 0em;
  }
  .h2-mob-small.contact {
    font-size: 28px;
  }
  .trusted-by-container {
    display: inline-block;
    text-align: center;
  }
  .contact-deet {
    font-size: 28px;
  }
  .site-wrapper {
    position: relative;
    z-index: 2;
  }
  .client-listing {
    width: 50%;
  }
  .logo-block {
    display: inline-block;
    width: 50%;
    float: none;
  }
  .page-nav {
    margin-bottom: 0em;
  }
  .pagenav.right {
    width: 100%;
  }
  .pagenav.left {
    width: 100%;
    margin-bottom: 1em;
  }
  .menu-button {
    width: 100%;
  }
  .footer {
    padding-right: 2em;
    padding-left: 2em;
    background-position: 50% 100%;
    background-size: 100%;
  }
  .legal-mumbo-jumbo {
    display: block;
    width: 70%;
  }
}

@media (max-width: 479px) {
  h2 {
    text-align: center;
  }
  h3 {
    text-align: center;
  }
  p {
    text-align: left;
  }
  .hero {
    padding-right: 0em;
    padding-left: 0em;
  }
  .hero.service-hero.ind-exp {
    height: 240px;
    margin-bottom: 1em;
  }
  .hero.service-hero.strategy {
    height: 240px;
  }
  .top-header {
    height: 3em;
  }
  .logo {
    display: block;
    width: 100px;
    margin-top: 0em;
    margin-right: auto;
    margin-left: auto;
  }
  .logo.logov2 {
    width: 100%;
    margin-top: 0.75em;
  }
  .header-tag {
    margin-top: 4em;
  }
  .service-navbar {
    z-index: 6;
  }
  .service-nav {
    width: 100%;
    text-align: center;
  }
  .service-nav.w--current {
    text-align: center;
  }
  .service-nav-menu {
    z-index: 12;
  }
  .hero-h1 {
    margin-top: 2em;
    font-family: Museo, sans-serif;
    font-size: 33px;
    line-height: 1.47em;
    text-align: center;
  }
  .hero-h1.service-page {
    margin-top: 0em;
    font-size: 26px;
  }
  .home-content {
    padding-top: 1em;
    padding-right: 0em;
    padding-left: 0em;
  }
  .slide-copy {
    position: absolute;
    left: 0em;
    width: 100%;
    padding-top: 1em;
    padding-right: 1em;
    padding-left: 1em;
  }
  .z-index-1 {
    display: block;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
  .left-control {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .intro-statement {
    margin-top: 6em;
  }
  .col-100 {
    margin-bottom: 0em;
  }
  .row-cta {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
  .menu-btn {
    float: none;
    text-align: center;
  }
  .menu-btn.w--open {
    z-index: 2;
  }
  .h2-mob-small {
    font-size: 24px;
    line-height: 1.3em;
  }
  .h2-mob-small.contact {
    font-size: 22px;
    text-align: center;
  }
  .hero-h2 {
    text-align: left;
  }
  .content {
    margin-bottom: 0em;
    padding-top: 0em;
  }
  .trusted-by-container {
    margin-bottom: 0em;
  }
  .contact-deets-container {
    text-align: center;
  }
  .contact-deets {
    display: block;
  }
  .contact-deet {
    font-size: 22px;
  }
  .site-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }
  .prev-page {
    display: inline-block;
    width: 100%;
    margin-bottom: 1em;
    float: none;
  }
  .next-page {
    display: inline-block;
    width: 100%;
    float: none;
    text-align: right;
  }
  .page-nav {
    margin-bottom: 2em;
  }
  .legal-mumbo-jumbo {
    display: block;
    width: 100%;
    text-align: center;
  }
  .headerv2 {
    text-align: center;
  }
}

@font-face {
  font-family: 'Museo Bold';
  src: url('../fonts/Museo700-Regular-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo300-Regular-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}